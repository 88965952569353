import Header from "../components/Header";
import { useEffect, useState } from "react";
import {
  calculateHoursBetween,
  calculateSum,
  getAllUsers,
  getAttendanceRate,
  getRank,
  verifyPassword,
  deleteUser,
  createUser,
  signOutAll,
  verifySecret
} from "../firebase";
import { User, seasonEnd, seasonStart } from "../data/Types";
import NavButtons from "../components/NavButtons";
import { Button } from "react-bootstrap";
import Autocomplete from "../components/Autocomplete";
import default_user from "../imgs/default_user.png";
import EditUser from "../components/EditUser";
import Options from "../components/Options";

export default () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isVerified, setVerified] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [pwd, setPwd] = useState<string>("");
  const [userCreation, setUserCreation] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    refreshUsers();
  }, []);

  const refreshUsers = () => {
    getAllUsers().then(allUsers => {
      allUsers.forEach(u => {
        u.meetings = new Map(Object.entries(u.meetings));
      });

      setUsers(allUsers);
    });
  }

  const checkPassword = (password: string) => {
    verifyPassword(password).then(res => {
      res.json().then(async val => {
        if (val.result === true && 'accessCode' in val) {
          localStorage.setItem("secret", val.accessCode)

          if (!await verifySecret()) {
            setVerified(false)
          } else {
            setVerified(val.result);
          }
        } else {
          setVerified(false)
        }
      });
    });
  };

  const updateSearch = (search: string) => {
    setSearch(search);
    if (search.length === 0) {
      setUser(undefined);
      setUserCreation(false);
    } else if (users.filter(user => user.name.toLowerCase() === search.toLowerCase())[0]) {
      setUser(users.filter(user => user.name.toLowerCase() === search.toLowerCase())[0]);
      setUserCreation(false);
    } else {
      setUser(undefined);
      setUserCreation(true);
    }
  };

  return (
    <>
      <Header />
      <div>
        <NavButtons pageName="Admin" />
        {isVerified &&
          <>
            <Options users={users} />
          </>
        }
      </div>

      <div style={{ margin: "1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
        {isVerified ?
          <>
            <Autocomplete width={"21rem"} height={"500"} users={users} onItemSelected={(name: string) => updateSearch(name)} />

            {user ?
              <>
                <img src={default_user} style={{ borderRadius: "500px", width: "200px", marginTop: "2rem" }} />

                <div className="message">
                  <b style={{ fontSize: 20 }}>{user.name} ({user.graduation})</b>
                </div>

                <div className="statistics">
                  <p className="stat">Total Hours: {calculateHoursBetween(user, new Date(user.created.seconds), new Date()) + user.bonus}</p>
                  <p className="stat">Season Hours: {calculateHoursBetween(user, seasonStart, seasonEnd)}</p>
                  <p className="stat">Meetings Attended: {user.meetings.size} ({Math.round(getAttendanceRate(user, users, new Date(user.created.seconds * 1000), new Date()))}%)</p>
                  <p className="stat">Volunteer Hours: {user.volunteer}</p>
                  <p className="stat">Hours Rank (Out of {users.length}): #{getRank(user, users)}</p>
                </div>

                <div className="actions">
                  <EditUser user={user} onSave={() => refreshUsers()} />
                  <Button className="action" onClick={() => { deleteUser(user); refreshUsers(); setUser(undefined); }}>Delete</Button>
                </div>
              </>
              : (userCreation ?
                <>
                  <img src={default_user} style={{ borderRadius: "500px", width: "200px", marginTop: "2rem" }} />

                  <div className="message">
                    <b style={{ fontSize: 20 }}>{search}</b>
                  </div>

                  <Button className="action" onClick={() => { createUser(search); refreshUsers(); updateSearch(search); }}>Create</Button>
                </>
                :
                <>
                </>
              )
            }
          </>
          :
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <input type="password" style={{ marginRight: ".5rem" }} placeholder="Enter password..." onChange={(e) => setPwd(e.target.value)} value={pwd} />
              <Button onClick={() => checkPassword(pwd)}>Verify</Button>
            </div>
          </>
        }
      </div>
    </>
  );
};
import Header from "../components/Header";
import { useEffect, useState } from "react";
import {
  getAllUsers,
  getMeetingsBetween,
  getMembersAtMeeting,
  getTotalAttendanceRate,
} from "../firebase";
import { User, offseasonStart } from "../data/Types";
import NavButtons from "../components/NavButtons";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

interface Point<X, Y> {
  x: X;
  y: Y;
}

export default () => {
  const [users, setUsers] = useState<User[]>([]);
  const [ratevsdate, setRateVSDate] = useState<Object[]>([]);

  useEffect(() => {
    getAllUsers().then(allUsers => {
      allUsers.forEach(u => {
        u.meetings = new Map(Object.entries(u.meetings));
      });

      setUsers(allUsers);
    });
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      let d: Point<string, number>[] = []
      getMeetingsBetween(users, offseasonStart, new Date()).forEach((meeting) => {
        const rate = getTotalAttendanceRate(users, new Date(meeting)) * 100;
        if (Math.floor(rate) !== 0) {
          d.push({x: meeting, y: getTotalAttendanceRate(users, new Date(meeting)) * 100});
        }
      });
      
      d = d.sort((a: Point<string, number>, b: Point<string, number>) => new Date(a.x).getTime() - new Date(b.x).getTime())
      setRateVSDate(d);
    }
  }, [users]);

  const AnalyticsTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor: "white", borderRadius: 5, padding: 5, minWidth: 180}}>
          <p style={{color: "black", textDecoration: "underline", margin: 0}}>{Math.round(payload[0].value)}% ({getMembersAtMeeting(users, new Date(label)).length}) - {label}</p>
          <div>
            {getMembersAtMeeting(users, new Date(label))
              .map((user, key) => (
                <p key={key} style={{color: "black", margin: 0}}>{user.name}</p>
              ))
            }
          </div>
        </div>
      );
    }
  
    return null;
  };

  return (
    <>
      <Header />
      <div>
        <NavButtons pageName="Analytics"/>
      </div>

      <div>
        <LineChart width={400} height={400} data={ratevsdate}>
          <Line dataKey="y" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="x" />
          <YAxis unit={"%"}/>
          <Tooltip content={<AnalyticsTooltip />} position={{x: 400, y: 0 }} />
        </LineChart>
      </div>
    </>
  )
}
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import Attendance from "./pages/Attendance";
import Analytics from "./pages/Analytics";
import Admin from "./pages/Admin";
import Privacy from "./pages/Privacy";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/analytics" element={<Analytics />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  </Router>
);

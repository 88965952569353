import Header from "../components/Header";

export default () => {
  return (
    <>
      <Header />
      <div>
        <h1>Privacy Policy</h1>
        <p>Insert privacy policy</p>
      </div>
    </>
  )
}
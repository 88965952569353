export interface User {
  id: string;
  name: string;
  graduation: string;
  meetings: Map<string, FBDate[]>;
  volunteer: number;
  created: FBDate;
  bonus: number;
  migrated: boolean;
}

export interface FBUser {
  id: string;
  name: string;
  graduation: string;
  meetings: Object;
  volunteer: number;
  created: FBDate;
  bonus: number;
  migrated: boolean;
}

export interface FBDate {
  seconds: number;
  nanoseconds: number;
}

export const today = (): string => {
  return dateStr(new Date());
}

export const getFBDate = (): FBDate => {
  return {seconds: Math.round(new Date().getTime() / 1000), nanoseconds: 0} as FBDate;
}

export const dateStr = (date: Date): string => {
  return (date.getMonth() + 1) + "/" + (date.getDate()) + "/" + date.getFullYear();
}

// Must be updated every year
export const offseasonStart = new Date("4/21/2024");
export const seasonStart = new Date("1/4/2025");
export const seasonEnd = new Date("4/19/2025");